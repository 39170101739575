<template>
  <div class="listScroll_container" id="listScroll">
    <div class="listScroll_content" ref="listScrollList">
      <div class="listScroll_main">
        <slot name="listScrollHeader"></slot>
        <div class="item_bloak" v-if="list.length != 0">
          <div v-for="(item, index) in list" :key="item">
            <slot name="listItem" :cItems="item" :cIndex="index"></slot>
            <!-- slot-scope -->
          </div>
        </div>
        <loadings v-show="isPullingUp"></loadings>
        <div class="listScroll_nonedata" v-if="list.length == 0">
          暂没有数据哟
        </div>
        <div class="listScroll_nomore" v-if="list.length > 0 && noMore">
          暂没有更多数据了
        </div>
        <slot name="logo"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import tool from "@/common/tool";
import BScroll from "better-scroll";
import loadings from "@/components/unit/Loadings";
export default {
  name: "listScroll",
  props: {
    url: {
      type: String,
      default: "",
    },
    select: {
      type: Object,
      default: () => {
        return {};
      },
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: String,
  },
  data() {
    return {
      search: {
        page: 1,
        pagesize: 10,
      },
      useConfig: {
        haveMore: true,
      },
      list: [],
      collect: {},
      showIcon: false,
      scroll: undefined,
      scrollTop: 0,
      noMore: false,
      isPullingUp: false,
      isPullingDown: false,
      bounce_time: 600,
    };
  },
  beforeUnmount() {
    document
      .getElementById("listScroll")
      .removeEventListener("touchmove", this.$tools.noScroll, {
        passive: false,
      });
  },
  created() {
    this.$nextTick(() => {
      document
        .getElementById("listScroll")
        .addEventListener("touchmove", this.$tools.noScroll, {
          passive: false,
        });
    });
    this.search = { ...this.search, ...this.select };
    this.useConfig = {
      ...this.useConfig,
      ...this.config,
    };
    let data = this.$tools.getStorage("articleData", sessionStorage);
    if (this.type == "articleList") {
      if (data && this.type == "articleList") {
        this.list = data;
        this.$emit("receiveData", this.list);
      }
    } else {
      this.initData();
    }
    this.initScroll();
  },
  methods: {
    //保存当前页数据
    saveCurrDate() {
      this.$tools.setStorage("articleData", this.list, sessionStorage);
    },
    initData() {
      this.getData();
    },
    async getData(isClear = false) {
      // console.log("isClear :>> ", isClear);
      await this.questData(isClear);
      setTimeout(() => {
        this.initScroll();
      }, this.bounce_time);
    },
    searchData(search = {}) {
      this.search = { ...this.search, ...search };

      this.search.page += 1;
      this.getData(true);
      this.scroll.scrollTo(0, 0);
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.listScrollList, {
            click: true,
            mouseWheel: true,
            preventDefault: false,
            probeType: 3,
            bounce_time: this.bounce_time,
            pullUpLoad: true,
            pullDownRefresh: {
              threshold: 100,
              stop: 56,
            },
            pullUpRefresh: {
              threshold: 100,
              stop: 56,
            },
          });
          // 下拉刷新
          this.scroll.on("pullingDown", this.pullingDownHandler);
          // this.scroll.on("pullingUp", this.pullingUpHandler);
          this.scroll.on("scroll", (pos) => {
            tool.setStorage("scrollTop", pos.y || 0, sessionStorage);
          });
          const top = tool.getStorage("scrollTop", sessionStorage) || 0;
          this.scroll.scrollTo(0, top);
        } else {
          this.scroll.refresh();
        }
      });
    },
    async pullingDownHandler() {
      if (this.useConfig.haveMore) {
        // if (!this.noMore) {
        this.search.page += 1;
        this.isPullingDown = true;
        await this.questData(true);
        // }
        // this.search.page = 1;
        // this.noMore = false;
        // this.isPullingDown = true;
        // await this.questData(true);
      }
      this.finishPullDown();
    },
    async finishPullDown() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullDown();
          resolve();
        }, 600);
        resolve();
      });
      setTimeout(() => {
        this.isPullingDown = false;
        this.scroll.refresh();
      }, this.bounce_time);
    },
    async pullingUpHandler() {
      if (this.useConfig.haveMore) {
        if (!this.noMore) {
          this.search.page += 1;
          this.isPullingUp = true;
          await this.questData(true);
        }
      }
      this.finishPullUp();
    },
    async finishPullUp() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullUp();
          resolve();
        }, 600);
        resolve();
      });

      setTimeout(() => {
        this.isPullingUp = false;
        this.scroll.refresh();
      }, this.bounce_time);
    },
    async questData(isClear = false) {
      try {
        let data = await this.loadData();

        if (data.length < this.search.pagesize) {
          this.noMore = true;
          this.search.page = 0;
        } else {
          this.noMore = false;
        }
        this.list = isClear ? data : this.list.concat(data);
        // console.log(, this.list);
      } catch (err) {}
    },
    async loadData() {
      return new Promise((resolve) => {
        let url = tool.getURL(this.url, this.search);
        this.$axios
          .get(url)
          .then((response) => {
            let list = response.data || [];
            console.log("list :>> ", list);
            this.$emit("receiveResponse", response);
            resolve(list);
          })
          .catch((err) => {
            console.log(err);
            resolve([]);
          });
      });
    },
  },
  components: {
    loadings,
  },
};
</script>
<style>
.listScroll_container {
  height: 100%;
  width: 100%;
  position: relative;
  /* background:#f1f1f1;; */
  display: flex;
  flex-direction: column;
}
.listScroll_main {
  /* flex: 1 0 0; */
  display: flex;
  flex-direction: column;
}
.item_bloak {
  flex: 1 0 0;
}
.listScroll_tab_list {
  flex: 2.5rem 0 0;
  width: 100%;
  /* display: flex; */
  background: #fff;
  /* align-items: center; */
  font-size: 0.9325rem;
  padding: 0.065rem 0.5rem;
  border-bottom: 1px solid #ececec;
  position: relative;
}
.listScroll_tab_content_name,
.listScroll_tab_right_line {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.listScroll_tab_right_line div {
  flex: 1 0 0;
}
.listScroll_tab_right_line div > span {
  display: inline-block;
  /* height:100%; */
  width: 100%;
  text-align: center;
  border-right: 0.1rem solid #ececec;
}

.listScroll_tab_right_line:last-child div > span {
  border: none;
}
.listScroll_tab {
  flex: 1 0 0;
  text-align: center;
}

.listScroll_content {
  flex: 1 0 0;
  width: 100%;
  overflow: hidden;
}
.listScroll_nonedata {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 13px;
  flex: 1 0 0;
}
.listScroll_nomore {
  height: 30px;
  font-size: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.listScroll_container .box:first-child {
  margin-top: 0 !important;
}
</style>